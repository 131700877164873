import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Paginate from "../../components/Paginate";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import LayoutItem from "../../components/LayoutItem";
import { createCar, getAllCarTypeList } from "../../redux/actions/carActions";
import { toast } from "react-toastify";

function AddCarScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const page = location.search.split("&")[1]
    ? location.search.split("&")[1].split("=")[1]
    : 1;

  const imageRef = useRef();
  const logoRef = useRef();
  const [carType, setCarType] = useState("");
  const [carName, setCarName] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carYear, setCarYear] = useState("");
  const [carColor, setCarColor] = useState("");
  const [carMatricule, setCarMatricule] = useState("");
  const [carImage, setCarImage] = useState("");
  const [carLogo, setCarLogo] = useState("");
  // errors
  const [errorType, setErrorType] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorYear, setErrorYear] = useState("");
  const [errorMatricule, setErrorMatricule] = useState("");
  //
  const [loadEvent, setLoadEvent] = useState(false);
  const [showDiag, setShowDiag] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const getAllTypes = useSelector((state) => state.getAllCarType);
  const { carTypes, loadingCarType, errorCarType } = getAllTypes;

  const carAdd = useSelector((state) => state.createCar);
  const { loadingCarAdd, errorCarAdd, successCarAdd } = carAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getAllCarTypeList());
    }
  }, [navigate, userInfo, dispatch]);

  // add car
  const hundleConfirmEvent = async () => {
    setShowDiag(false);
    setErrorType("");
    setErrorName("");
    setErrorYear("");
    setErrorMatricule("");
    //
    if (
      carType === "" ||
      carName === "" ||
      carYear === "" ||
      carMatricule === ""
    ) {
      if (carType === "") {
        setErrorType("Veuillez sélectionner le type de la voiture");
      }
      if (carName === "") {
        setErrorName("Veuillez sélectionner le Nom de la voiture");
      }
      if (carYear === "") {
        setErrorYear("Veuillez sélectionner l'année de la voiture");
      }
      if (carMatricule === "") {
        setErrorMatricule("Veuillez sélectionner le matricule de voiture");
      }
      toast.error("Certains champs ne sont pas valides, veuillez confirmer", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoadEvent(true);
      await dispatch(
        createCar({
          car_type: carType,
          car_name: carName,
          car_model: carModel,
          car_year: carYear,
          car_color: carColor,
          car_matricul: carMatricule,
          car_image: carImage,
          car_logo: carLogo,
        })
      ).then(() => {});
      setLoadEvent(false);
    }
  };
  useEffect(() => {
    if (successCarAdd) {
      setCarColor("");
      setCarImage("");
      setCarLogo("");
      setCarMatricule("");
      setCarModel("");
      setCarName("");
      setCarType("");
      setCarYear("");
    }
  }, [successCarAdd]);

  return (
    <DefaultLayout>
      {loading ? (
        <Loading />
      ) : error ? (
        <Alert type="error" message={error} />
      ) : (
        <div>
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="py-6 px-4 md:px-6 xl:px-7.5 flex justify-between">
              <h4 className="text-xl font-semibold text-black ">
                Ajouter une voiture
              </h4>
            </div>
            {/*  */}
            <div className="w-full">
              <LayoutItem title="Informations sur le véhicule">
                {/* type */}
                <div className="md:w-1/2 w-full mb-6">
                  <label>
                    Type de voiture{" "}
                    <span className="text-danger text-sm">*</span>
                  </label>
                  <select
                    id="typecar"
                    value={carType}
                    onChange={(value) => {
                      setCarType(value.target.value);
                    }}
                    className="mt-1 block w-full p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                  >
                    <option value="">Sélectionnez le type de véhicule</option>
                    {carTypes?.map((carType, index) => (
                      <option key={index} value={carType.id}>
                        {carType.cartype_name}
                      </option>
                    ))}
                  </select>
                  {errorType && (
                    <p className="text-xs italic text-danger">{errorType}</p>
                  )}
                </div>
                {/* name  and model */}
                <div className="md:flex">
                  {/* name */}
                  <div className="md:w-1/2 w-full md:pr-1 mb-6">
                    <label>
                      Nom de voiture
                      <span className="text-danger text-sm">*</span>
                    </label>
                    <input
                      type="text"
                      value={carName}
                      onChange={(value) => {
                        setCarName(value.target.value);
                      }}
                      className="mt-1 block w-full p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      placeholder="Toyota, Ford, Honda ..."
                    />
                    {errorName && (
                      <p className="text-xs italic text-danger">{errorName}</p>
                    )}
                  </div>
                  {/* Model */}
                  <div className="md:w-1/2 w-full md:pl-1 mb-6">
                    <label>Model de voiture</label>
                    <input
                      type="text"
                      value={carModel}
                      onChange={(value) => {
                        setCarModel(value.target.value);
                      }}
                      className="mt-1 block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      placeholder="Camry, Prius, Civic ..."
                    />
                  </div>
                </div>
                {/* years and  */}
                <div className="md:flex">
                  {/* years */}
                  <div className="md:w-1/2 w-full md:pr-1 mb-6">
                    <label>
                      Année de voiture
                      <span className="text-danger text-sm">*</span>
                    </label>
                    <input
                      type="text"
                      value={carYear}
                      onChange={(value) => {
                        setCarYear(value.target.value);
                      }}
                      className="mt-1 block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      placeholder="2010"
                    />
                    {errorYear && (
                      <p className="text-xs italic text-danger">{errorYear}</p>
                    )}
                  </div>
                  {/* color */}
                  <div className="md:w-1/2 w-full md:pl-1 mb-6">
                    <label>Coleur de voitue</label>
                    <input
                      type="text"
                      value={carColor}
                      onChange={(value) => {
                        setCarColor(value.target.value);
                      }}
                      className="mt-1 block w-full p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      placeholder="Black, Blue, White ..."
                    />
                  </div>
                </div>

                {/* Matricul */}
                <div className="md:flex">
                  {/* Matricul */}
                  <div className="md:w-1/2 w-full md:pr-1 mb-6">
                    <label>
                      Immatriculation
                      <span className="text-danger text-sm">*</span>
                    </label>
                    <input
                      type="text"
                      value={carMatricule}
                      onChange={(value) => {
                        setCarMatricule(value.target.value);
                      }}
                      className="mt-1 block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      placeholder="00000-A-00"
                    />
                    {errorMatricule && (
                      <p className="text-xs italic text-danger">
                        {errorMatricule}
                      </p>
                    )}
                  </div>
                </div>

                {/* logo - photo */}
                <div className="md:flex">
                  {/* Matricul */}
                  <div className="md:w-1/2 w-full md:pr-1 mb-6">
                    <label>Image</label>
                    <input
                      type="file"
                      ref={imageRef}
                      accept="image/*"
                      onChange={(value) => {
                        setCarImage(value.target.files[0]);
                      }}
                      className="mt-1 block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    />
                  </div>
                  <div className="md:w-1/2 w-full md:pl-1 mb-6">
                    <label>logo</label>
                    <input
                      type="file"
                      ref={logoRef}
                      accept="image/*"
                      onChange={(value) => {
                        setCarLogo(value.target.files[0]);
                      }}
                      className="mt-1 block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    />
                  </div>
                </div>

                {/* save */}
                <div className="flex justify-end gap-x-3 pt-4">
                  <button
                    onClick={() => setShowDiag(true)}
                    className="border py-2 px-8 text-white rounded-md text-sm bg-primary"
                  >
                    Sauvgarder
                  </button>
                  <button
                    onClick={() => {
                      navigate("/cars");
                    }}
                    className=" py-2 px-8 text-primary text-sm"
                  >
                    Retour
                  </button>
                </div>
              </LayoutItem>
            </div>
          </div>
          {/* model */}
          {showDiag ? (
            <>
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                <div className="bg-white p-6 rounded shadow-md">
                  <h3 className="text-lg font-bold mb-4">Confirmation</h3>
                  <p className="mb-4">
                    Es-tu sûr de vouloir ajouter cette voiture ?
                  </p>
                  <div className="flex justify-end">
                    <button
                      className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                      onClick={() => hundleConfirmEvent()}
                      disabled={loadEvent}
                    >
                      {" "}
                      {loadEvent ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Confirm"
                      )}{" "}
                    </button>
                    <button
                      className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      onClick={() => {
                        setShowDiag(false);
                        setLoadEvent(false);
                      }}
                      disabled={loadEvent}
                    >
                      {" "}
                      Annuler{" "}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      )}
    </DefaultLayout>
  );
}

export default AddCarScreen;
