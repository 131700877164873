import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { login } from "../../redux/actions/userActions";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";

function LoginScreen() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  // const redirect = '/dashboard'

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [navigate, userInfo]);

  const submitHandle = async (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="flex justify-center items-center h-screen">
        <form
          className="bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 md:w-1/3 w-screen"
          onSubmit={submitHandle}
        >
          <h2 className="text-2xl mb-6">Connectez-vous à l'administrateur</h2>
          {error && <Alert type="error" message={error} />}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Adresse e-mail
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder=""
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mot de passe
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="border border-primary bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Connexion
            </button>
            <a
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              href="#!"
            >
              Mot de passe oublié?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;
