import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  //
  USER_CLIENT_REQUEST,
  USER_CLIENT_SUCCESS,
  USER_CLIENT_FAIL,
  //
  USER_DRIVER_REQUEST,
  USER_DRIVER_SUCCESS,
  USER_DRIVER_FAIL,
  //
  USER_ACTIF_DEACTIVE_REQUEST,
  USER_ACTIF_DEACTIVE_SUCCESS,
  USER_ACTIF_DEACTIVE_FAIL,
  //
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  //
  USER_CLIENT_ADD_REQUEST,
  USER_CLIENT_ADD_SUCCESS,
  USER_CLIENT_ADD_FAIL,
  //
  USER_DRIVER_ADD_REQUEST,
  USER_DRIVER_ADD_SUCCESS,
  USER_DRIVER_ADD_FAIL,
  //
} from "../constants/userConstants";
// add driver
export const addUserDriverReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DRIVER_ADD_REQUEST:
      return { loadingAddDriver: true };
    case USER_DRIVER_ADD_SUCCESS:
      return { loadingAddDriver: false, successAddDriver: true };
    case USER_DRIVER_ADD_FAIL:
      return { loadingAddDriver: false, errorAddDriver: action.payload };
    default:
      return state;
  }
};

// add client
export const addUserClientReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CLIENT_ADD_REQUEST:
      return { loadingAddClient: true };
    case USER_CLIENT_ADD_SUCCESS:
      return { loadingAddClient: false, successAddClient: true };
    case USER_CLIENT_ADD_FAIL:
      return { loadingAddClient: false, errorAddClient: action.payload };
    default:
      return state;
  }
};

// delete user
export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// active or deactive user
export const actifDeactifUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIF_DEACTIVE_REQUEST:
      return { loadingAct: true };
    case USER_ACTIF_DEACTIVE_SUCCESS:
      return { loadingAct: false };
    case USER_ACTIF_DEACTIVE_FAIL:
      return { loadingAct: false, errorAct: action.payload };
    default:
      return state;
  }
};

export const driverListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_DRIVER_REQUEST:
      return { loading: true, users: [] };
    case USER_DRIVER_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_DRIVER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const clientListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_CLIENT_REQUEST:
      return { loading: true, users: [] };
    case USER_CLIENT_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
