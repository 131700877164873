import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen.js";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import DriverScreen from "./screens/drivers/DriverScreen.js";
import ClientScreen from "./screens/clients/ClientScreen.js";
import AddDriverScreen from "./screens/drivers/AddDriverScreen.js";
import AddClientScreen from "./screens/clients/AddClientScreen.js";
import CurrentOrdersScreen from "./screens/orders/CurrentOrdersScreen.js";
import OrderDetailScreen from "./screens/orders/OrderDetailScreen.js";
import HistoryOrdersScreen from "./screens/orders/HistoryOrdersScreen.js";
import CarListScreen from "./screens/cars/CarListScreen.js";
import AddCarScreen from "./screens/cars/AddCarScreen.js";
import EditCarScreen from "./screens/cars/EditCarScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  // drivers
  {
    path: "/drivers",
    element: <DriverScreen />,
  },
  {
    path: "/drivers/add",
    element: <AddDriverScreen />,
  },
  // clients
  {
    path: "/clients",
    element: <ClientScreen />,
  },
  {
    path: "/clients/add",
    element: <AddClientScreen />,
  },
  // orders
  {
    path: "/current-orders",
    element: <CurrentOrdersScreen />,
  },
  {
    path: "/current-orders/:id",
    element: <OrderDetailScreen />,
  },
  {
    path: "/history-orders",
    element: <HistoryOrdersScreen />,
  },
  {
    path: "/history-orders/:id",
    element: <OrderDetailScreen />,
  },
  // car
  {
    path: "/cars",
    element: <CarListScreen />,
  },
  {
    path: "/cars/add",
    element: <AddCarScreen />,
  },
  {
    path: "/cars/:id",
    element: <EditCarScreen />,
  },

  // logout
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
