export const LIST_CURRENT_ORDER_REQUEST = "LIST_CURRENT_ORDER_REQUEST";
export const LIST_CURRENT_ORDER_SUCCESS = "LIST_CURRENT_ORDER_SUCCESS";
export const LIST_CURRENT_ORDER_FAIL = "LIST_CURRENT_ORDER_FAIL";

export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL";

export const ORDER_INVITATION_REQUEST = "ORDER_INVITATION_REQUEST";
export const ORDER_INVITATION_SUCCESS = "ORDER_INVITATION_SUCCESS";
export const ORDER_INVITATION_FAIL = "ORDER_INVITATION_FAIL";

export const ORDER_INVITATION_DELETE_REQUEST =
  "ORDER_INVITATION_DELETE_REQUEST";
export const ORDER_INVITATION_DELETE_SUCCESS =
  "ORDER_INVITATION_DELETE_SUCCESS";
export const ORDER_INVITATION_DELETE_FAIL = "ORDER_INVITATION_DELETE_FAIL";

export const LIST_HISTORY_ORDER_REQUEST = "LIST_HISTORY_ORDER_REQUEST";
export const LIST_HISTORY_ORDER_SUCCESS = "LIST_HISTORY_ORDER_SUCCESS";
export const LIST_HISTORY_ORDER_FAIL = "LIST_HISTORY_ORDER_FAIL";

export const ORDER_UPDATE_CAR_REQUEST = "ORDER_UPDATE_CAR_REQUEST";
export const ORDER_UPDATE_CAR_SUCCESS = "ORDER_UPDATE_CAR_SUCCESS";
export const ORDER_UPDATE_CAR_FAIL = "ORDER_UPDATE_CAR_FAIL";
