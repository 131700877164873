import { toast } from "react-toastify";
import {
  CAR_TYPE_LIST_FAIL,
  CAR_TYPE_LIST_REQUEST,
  CAR_TYPE_LIST_SUCCESS,
  //
  CAR_CREATE_FAIL,
  CAR_CREATE_REQUEST,
  CAR_CREATE_SUCCESS,
  //
  CAR_LIST_FAIL,
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  //
  CAR_DELETE_FAIL,
  CAR_DELETE_REQUEST,
  CAR_DELETE_SUCCESS,
} from "../constants/carConstants";

export const deleteCarReducer = (state = {}, action) => {
  switch (action.type) {
    case CAR_DELETE_REQUEST:
      return { loadingDelete: true };
    case CAR_DELETE_SUCCESS:
      return { loadingDelete: false };
    case CAR_DELETE_FAIL:
      return { loadingDelete: false, errorDelete: action.payload };
    default:
      return state;
  }
};

export const getAllCarsReducer = (state = { cars: [] }, action) => {
  switch (action.type) {
    case CAR_LIST_REQUEST:
      return { loadingCar: true, cars: [] };
    case CAR_LIST_SUCCESS:
      return {
        loadingCar: false,
        cars: action.payload.cars,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CAR_LIST_FAIL:
      return { loadingCar: false, errorCar: action.payload };
    default:
      return state;
  }
};

//   get all car type
export const getAllCarTypeReducer = (state = { carTypes: [] }, action) => {
  switch (action.type) {
    case CAR_TYPE_LIST_REQUEST:
      return { loadingCarType: true, carTypes: [] };
    case CAR_TYPE_LIST_SUCCESS:
      return {
        loadingCarType: false,
        carTypes: action.payload.cartypes,
      };
    case CAR_TYPE_LIST_FAIL:
      return { loadingCarType: false, errorCarType: action.payload };
    default:
      return state;
  }
};

export const createCarReducer = (state = {}, action) => {
  switch (action.type) {
    case CAR_CREATE_REQUEST:
      return { loadingCarAdd: true };
    case CAR_CREATE_SUCCESS:
      toast.success("Ce véhicule a été ajouté avec succès", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return {
        loadingCarAdd: false,
        successCarAdd: true,
      };
    case CAR_CREATE_FAIL:
      toast.error(action.payload, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return {
        loadingCarType: false,
        successCarAdd: false,
        errorCarType: action.payload,
      };
    default:
      return state;
  }
};
