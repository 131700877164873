import axios from "../../axios";
import {
  LIST_CURRENT_ORDER_REQUEST,
  LIST_CURRENT_ORDER_SUCCESS,
  LIST_CURRENT_ORDER_FAIL,
  //
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  //
  ORDER_INVITATION_REQUEST,
  ORDER_INVITATION_SUCCESS,
  ORDER_INVITATION_FAIL,
  //
  ORDER_INVITATION_DELETE_REQUEST,
  ORDER_INVITATION_DELETE_SUCCESS,
  ORDER_INVITATION_DELETE_FAIL,
  //
  LIST_HISTORY_ORDER_REQUEST,
  LIST_HISTORY_ORDER_SUCCESS,
  LIST_HISTORY_ORDER_FAIL,
  //
  ORDER_UPDATE_CAR_REQUEST,
  ORDER_UPDATE_CAR_SUCCESS,
  ORDER_UPDATE_CAR_FAIL,
} from "../constants/ordersConstants";

import { toast } from "react-toastify";

// update car on order
export const updateOrderCar = (order, car) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_UPDATE_CAR_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(
      `/orders/order-detail/${order}/update-car/${car}/`,
      {},
      config
    );

    dispatch({
      type: ORDER_UPDATE_CAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_UPDATE_CAR_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get history orders
export const historyOrderList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_HISTORY_ORDER_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/orders/history-orders/?page=${page}`,
      config
    );

    dispatch({
      type: LIST_HISTORY_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_HISTORY_ORDER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete invitation
export const deleteInvitationOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_INVITATION_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(
      `/orders/delete-invitation/${id}/`,
      config
    );

    dispatch({
      type: ORDER_INVITATION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_INVITATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// send invitation
export const sendInvitationOrder = (dataInvt) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_INVITATION_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/orders/send-invitation/`,
      dataInvt,
      config
    );

    dispatch({
      type: ORDER_INVITATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_INVITATION_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get order detail
export const getOrderDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/orders/order-detail/${id}/`, config);

    dispatch({
      type: ORDER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// list current orders
export const currentOrderList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_CURRENT_ORDER_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/orders/current-orders/?page=${page}`,
      config
    );

    dispatch({
      type: LIST_CURRENT_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_CURRENT_ORDER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
