import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  actifDeactifUserReducer,
  addUserClientReducer,
  addUserDriverReducer,
  clientListReducer,
  deleteUserReducer,
  driverListReducer,
  userLoginReducer,
} from "./reducers/userReducers";
import {
  currentOrderListReducer,
  deleteInvitationOrderReducer,
  getOrderDetailReducer,
  historyOrderListReducer,
  sendInvitationOrderReducer,
  updateOrderCarReducer,
} from "./reducers/orderReducers";
import {
  createCarReducer,
  deleteCarReducer,
  getAllCarTypeReducer,
  getAllCarsReducer,
} from "./reducers/carsReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  driverList: driverListReducer,
  clientList: clientListReducer,
  actifDeactifUser: actifDeactifUserReducer,
  deleteUser: deleteUserReducer,
  addUserClient: addUserClientReducer,
  addUserDriver: addUserDriverReducer,
  // orders
  currentOrderList: currentOrderListReducer,
  getOrderDetail: getOrderDetailReducer,
  sendInvitationOrder: sendInvitationOrderReducer,
  deleteInvitationOrder: deleteInvitationOrderReducer,
  historyOrderList: historyOrderListReducer,
  updateOrderCar: updateOrderCarReducer,

  // car types
  getAllCarType: getAllCarTypeReducer,
  createCar: createCarReducer,
  getAllCars: getAllCarsReducer,
  deleteCar: deleteCarReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfoChauffeurMarocPrive")
  ? JSON.parse(localStorage.getItem("userInfoChauffeurMarocPrive"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
  // composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
