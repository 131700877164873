import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/icons/logo_chauffeur_prive.png";

function Sidebar({ props, sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const [openTres, setOpenTres] = useState(false);
  const [openVille, setOpenVille] = useState(false);
  const [openOrders, setOpenOrders] = useState(
    pathname.includes("current-orders") || pathname.includes("history-orders")
      ? true
      : false
  );

  const dispatch = useDispatch();

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo, error, loading } = userLogin;

  // const infoDashMenuStatic = useSelector((state) => state.dashStaticMenuInfo);
  // const { loadingDashMenu, errorDashMenu, dashStaticMenu } = infoDashMenuStatic;

  const [codeSearch, setCodeSearch] = useState("");

  return (
    <aside
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-[#f9fafa] shadow duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="/dashboard" className="w-full">
          <img src={logo} alt="Logo" className="text-white mx-auto w-16" />
        </NavLink>

        <button
          // ref={trigger}
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden text-black"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-1 py-4 px-4 lg:mt-9 lg:px-6">
          {/* <!-- Menu Group --> */}
          <div>
            {/*  */}
            <ul className="mb-6 flex flex-col gap-1.5">
              {/* Tableau de bord */}
              <li>
                <NavLink
                  to="/dashboard"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("dashboard") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 3V11.743L5.63599 18.364"
                      stroke="#7FD082"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.89999 8.08008L12 11.7401"
                      stroke="#7FD082"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.3639C14.8493 21.8787 9.1508 21.8787 5.6361 18.3639C2.12138 14.8492 2.12138 9.15074 5.6361 5.63604C9.15082 2.12132 14.8493 2.12132 18.364 5.63604"
                      stroke="#7FD082"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Tableau de bord
                </NavLink>
              </li>
              {/* drivers */}
              <li>
                <NavLink
                  to="/drivers"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("drivers") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke="#7FD082"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                  Chauffeurs
                </NavLink>
              </li>
              {/* client */}
              <li>
                <NavLink
                  to="/clients"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("clients") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke="#7FD082"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                    />
                  </svg>
                  Clients
                </NavLink>
              </li>
              {/* Gestion des voitures */}
              <li>
                <NavLink
                  to="/cars"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("cars") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke="#7FD082"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                    />
                    <path
                      stroke="#7FD082"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  Gestion des voitures
                </NavLink>
              </li>
              {/* order */}
              <li>
                <NavLink
                  // to="/tresoreries"
                  onClick={() => setOpenOrders(!openOrders)}
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    (pathname.includes("current-orders") ||
                      pathname.includes("history-orders")) &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  } justify-between`}
                >
                  <div className="group flex items-center gap-2.5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke="#7FD082"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                      />
                    </svg>
                    Gestion des orders
                  </div>
                  <svg
                    className={`w-6 h-6 transform transition-transform duration-300 ${
                      openOrders ? "rotate-180" : "rotate-90"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </NavLink>
              </li>
              <div
                className={`${
                  !openOrders ? "h-0" : ""
                } overflow-hidden transition-all duration-500`}
              >
                <li>
                  <NavLink
                    to="/current-orders"
                    className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                      pathname.includes("current-order") &&
                      "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                    }`}
                  >
                    <span className="w-6 h-6"></span>
                    Current Orders
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/history-orders"
                    className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                      pathname.includes("history-orders") &&
                      "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                    }`}
                  >
                    <span className="w-6 h-6"></span>
                    History Orders
                  </NavLink>
                </li>
              </div>

              {/* notification */}
              <li>
                <NavLink
                  to="#"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("notifications") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke="#7FD082"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                    />
                  </svg>
                  Notifications
                </NavLink>
              </li>
              {/* Déconnexion */}
              <li>
                <NavLink
                  to="/logout"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("logout") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1946 6.34277C21.3186 9.46677 21.3186 14.5328 18.1946 17.6568C15.0706 20.7808 10.0046 20.7808 6.8806 17.6568C3.7566 14.5328 3.7566 9.46677 6.8806 6.34277"
                      stroke="#DB3C3F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5376 4V12"
                      stroke="#DB3C3F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Déconnexion
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
