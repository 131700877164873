import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addUserDriver, addUserLivreur } from "../../redux/actions/userActions";
import LayoutItem from "../../components/LayoutItem";

function AddDriverScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const addDriver = useSelector((state) => state.addUserDriver);
  const { loadingAddDriver, errorAddDriver, successAddDriver } = addDriver;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      // dispatch(optionProductList());
    }
  }, [navigate, userInfo]);

  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [city, setCity] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [address, setAddress] = useState("");
  const [errorAddress, setErrorAddress] = useState("");

  const [showDiag, setShowDiag] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const hundleShowModel = () => {
    setShowDiag(true);
  };

  const hundleConfirmEvent = async () => {
    setLoadEvent(true);
    await dispatch(
      addUserDriver({
        email: email,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
        address_user: address,
        city: city,
        password: password,
      })
    ).then(() => {});
    hundleHideModel();
  };

  const hundleHideModel = () => {
    setShowDiag(false);
    setLoadEvent(false);
  };

  const addClientHundl = async () => {
    setErrorFirstName("");
    setErrorLastName("");
    setErrorPhone("");
    setErrorEmail("");
    setErrorPassword("");
    setErrorConfirmPassword("");
    setErrorCity("");
    setErrorAddress("");

    if (firstName === "") {
      setErrorFirstName("Veuillez ajouter le prénom du chauffeur");
      toast.error("Veuillez ajouter le prénom du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (lastName === "") {
      setErrorLastName("Veuillez ajouter le nom du chauffeur");
      toast.error("Veuillez ajouter le nom du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (phone === "") {
      setErrorPhone("Veuillez ajouter le numéro de téléphone du chauffeur");
      toast.error("Veuillez ajouter le numéro de téléphone du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (city === "") {
      setErrorCity("Veuillez ajouter le ville du chauffeur");
      toast.error("Veuillez ajouter le ville du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (address === "") {
      setErrorAddress("Veuillez ajouter l'adresse du chauffeur");
      toast.error("Veuillez ajouter l'adresse du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (email === "") {
      setErrorEmail("Veuillez ajouter le email du chauffeur");
      toast.error("Veuillez ajouter le email du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (password === "") {
      setErrorPassword("Veuillez ajouter le mot de passe du chauffeur");
      toast.error("Veuillez ajouter le mot de passe du chauffeur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (confirmPassword === "" || confirmPassword !== password) {
      setErrorConfirmPassword("Veuillez confirmer le mot de passe");
      toast.error("Veuillez confirmer le mot de passe", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      hundleShowModel();
    }
  };

  useEffect(() => {
    if (successAddDriver) {
      setFirstName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setCity("");
      setAddress("");
    }
  }, [successAddDriver]);

  return (
    <DefaultLayout>
      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="py-6 px-4 md:px-6 xl:px-7.5 ">
          <h4 className="text-xl font-semibold text-black dark:text-white">
            Ajouter un Chauffeur
          </h4>
        </div>
        {/* info client */}
        <LayoutItem title="Informations personnelles">
          <div className="mt-2">
            {/* fisrt name & last name */}
            <div className="py-2 md:flex">
              <div className="md:flex-1 md:mr-1 md:mb-0 mb-1">
                <label className="">
                  Prénom <span className="text-danger text-sm">*</span>
                </label>
                <div className={`border rounded-md mt-1`}>
                  <input
                    type="text"
                    value={firstName}
                    placeholder="Entrez le Prénom"
                    className="p-2 w-full outline-none focus:border-primary bg-transparent"
                    onChange={(v) => setFirstName(v.target.value)}
                  />
                </div>
                {errorFirstName && (
                  <p className="text-xs italic text-danger">
                    {" "}
                    {errorFirstName}{" "}
                  </p>
                )}
              </div>

              <div className="md:flex-1 md:mt-0 mt-1">
                <label className="">
                  Nom <span className="text-danger text-sm">*</span>
                </label>
                <div className={`border rounded-md mt-1`}>
                  <input
                    type="text"
                    value={lastName}
                    placeholder="Entrez le Nom"
                    className="p-2 w-full outline-none focus:border-primary bg-transparent"
                    onChange={(v) => setLastName(v.target.value)}
                  />
                </div>
                {errorLastName && (
                  <p className="text-xs italic text-danger">
                    {" "}
                    {errorLastName}{" "}
                  </p>
                )}
              </div>
            </div>
            {/* phone */}
            <div className="py-2 md:flex">
              <div className="md:flex-1 md:mr-1 md:mb-0 mb-1">
                <label className="">
                  Téléphone <span className="text-danger text-sm">*</span>
                </label>
                <div className={`border rounded-md mt-1`}>
                  <input
                    type="text"
                    value={phone}
                    placeholder="Entrez le Téléphone"
                    className="p-2 w-full outline-none focus:border-primary bg-transparent"
                    onChange={(v) => setPhone(v.target.value)}
                  />
                </div>
                {errorPhone && (
                  <p className="text-xs italic text-danger"> {errorPhone} </p>
                )}
              </div>
              <div className="md:flex-1 md:mt-0 mt-1"></div>
            </div>
            {/* city & address */}
            <div className="py-2 md:flex">
              <div className="md:flex-1 md:mr-1 md:mb-0 mb-1">
                <label className="">
                  Ville <span className="text-danger text-sm">*</span>
                </label>
                <div className={`border rounded-md mt-1`}>
                  <input
                    type="text"
                    value={city}
                    placeholder="Entrez le Ville"
                    className="p-2 w-full outline-none focus:border-primary bg-transparent"
                    onChange={(v) => setCity(v.target.value)}
                  />
                </div>
                {errorCity && (
                  <p className="text-xs italic text-danger"> {errorCity} </p>
                )}
              </div>

              <div className="md:flex-1 md:mt-0 mt-1">
                <label className="">
                  Adresse <span className="text-danger text-sm">*</span>
                </label>
                <div className={`border rounded-md mt-1`}>
                  <input
                    type="text"
                    value={address}
                    placeholder="Entrez l'Adresse"
                    className="p-2 w-full outline-none focus:border-primary bg-transparent"
                    onChange={(v) => setAddress(v.target.value)}
                  />
                </div>
                {errorAddress && (
                  <p className="text-xs italic text-danger"> {errorAddress} </p>
                )}
              </div>
            </div>
          </div>
        </LayoutItem>

        {/*  */}
        <LayoutItem title="Informations de connexion">
          <div className="py-2 md:flex">
            <div className="md:flex-1 md:mr-1 md:mb-0 mb-1">
              <label className="">
                Email <span className="text-danger text-sm">*</span>
              </label>
              <div className={`border rounded-md mt-1`}>
                <input
                  type="email"
                  value={email}
                  placeholder="Entrez le Email"
                  className="p-2 w-full outline-none focus:border-primary bg-transparent"
                  onChange={(v) => setEmail(v.target.value)}
                />
              </div>
              {errorEmail && (
                <p className="text-xs italic text-danger"> {errorEmail} </p>
              )}
            </div>

            <div className="md:flex-1 md:mt-0 mt-1"></div>
          </div>
          {/*  */}
          <div className="py-2 md:flex">
            <div className="md:flex-1 md:mr-1 md:mb-0 mb-1">
              <label className="">
                Mot de passe <span className="text-danger text-sm">*</span>
              </label>
              <div className={`border rounded-md mt-1`}>
                <input
                  type="password"
                  value={password}
                  placeholder="Entrez le Mot de passe"
                  className="p-2 w-full outline-none focus:border-primary bg-transparent"
                  onChange={(v) => setPassword(v.target.value)}
                />
              </div>
              {errorPassword && (
                <p className="text-xs italic text-danger"> {errorPassword} </p>
              )}
            </div>

            <div className="md:flex-1 md:mt-0 mt-1">
              <label className="">
                Confirmez le mot de passe{" "}
                <span className="text-danger text-sm">*</span>
              </label>
              <div className={`border rounded-md mt-1`}>
                <input
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirmez le mot de passe"
                  className="p-2 w-full outline-none focus:border-primary bg-transparent"
                  onChange={(v) => setConfirmPassword(v.target.value)}
                />
              </div>
              {errorConfirmPassword && (
                <p className="text-xs italic text-danger">
                  {" "}
                  {errorConfirmPassword}{" "}
                </p>
              )}
            </div>
          </div>
        </LayoutItem>
        <div className="flex justify-end gap-x-3 pt-4">
          <button
            onClick={addClientHundl}
            className="border py-2 px-8 text-white rounded-md text-sm bg-primary"
          >
            Sauvgarder
          </button>
          <button
            onClick={() => {
              navigate("/livreurs");
            }}
            className=" py-2 px-8 text-primary text-sm"
          >
            Retour
          </button>
        </div>
        <div className="h-24"></div>
      </div>
      {/* model */}
      {showDiag ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-md">
              <h3 className="text-lg font-bold mb-4">Confirmation</h3>
              <p className="mb-4">Voulez-vous confirmer votre choix ?</p>
              <div className="flex justify-end">
                <button
                  className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={() => hundleConfirmEvent()}
                  disabled={loadEvent}
                >
                  {" "}
                  {loadEvent ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Confirm"
                  )}{" "}
                </button>
                <button
                  className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={() => hundleHideModel()}
                  disabled={loadEvent}
                >
                  {" "}
                  Annuler{" "}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </DefaultLayout>
  );
}

export default AddDriverScreen;
