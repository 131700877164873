import {
  LIST_CURRENT_ORDER_REQUEST,
  LIST_CURRENT_ORDER_SUCCESS,
  LIST_CURRENT_ORDER_FAIL,
  //
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  //
  ORDER_INVITATION_REQUEST,
  ORDER_INVITATION_SUCCESS,
  ORDER_INVITATION_FAIL,
  //
  ORDER_INVITATION_DELETE_REQUEST,
  ORDER_INVITATION_DELETE_SUCCESS,
  ORDER_INVITATION_DELETE_FAIL,
  //
  LIST_HISTORY_ORDER_REQUEST,
  LIST_HISTORY_ORDER_SUCCESS,
  LIST_HISTORY_ORDER_FAIL,
  //
  ORDER_UPDATE_CAR_REQUEST,
  ORDER_UPDATE_CAR_SUCCESS,
  ORDER_UPDATE_CAR_FAIL,
  //
} from "../constants/ordersConstants";

export const updateOrderCarReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_CAR_REQUEST:
      return { loadingUpdateOrderCar: true };
    case ORDER_UPDATE_CAR_SUCCESS:
      return {
        loadingUpdateOrderCar: false,
        successUpdateOrderCar: true,
      };
    case ORDER_UPDATE_CAR_FAIL:
      return {
        loadingUpdateOrderCar: false,
        successUpdateOrderCar: false,
        errorUpdateOrderCar: action.payload,
      };
    default:
      return state;
  }
};

// get histpry orders
export const historyOrderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case LIST_HISTORY_ORDER_REQUEST:
      return { loading: true, orders: [] };
    case LIST_HISTORY_ORDER_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case LIST_HISTORY_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// delete Invitation Order
export const deleteInvitationOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_INVITATION_DELETE_REQUEST:
      return { loadingDelete: true };
    case ORDER_INVITATION_DELETE_SUCCESS:
      return {
        loadingDelete: false,
        successDelete: true,
      };
    case ORDER_INVITATION_DELETE_FAIL:
      return {
        loadingDelete: false,
        successDelete: false,
        errorDelete: action.payload,
      };
    default:
      return state;
  }
};

// send invitation
export const sendInvitationOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_INVITATION_REQUEST:
      return { loadingSend: true };
    case ORDER_INVITATION_SUCCESS:
      return {
        loadingSend: false,
        successSend: true,
      };
    case ORDER_INVITATION_FAIL:
      return {
        loadingSend: false,
        successSend: false,
        errorSend: action.payload,
      };
    default:
      return state;
  }
};

// get order detail
export const getOrderDetailReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_DETAIL_REQUEST:
      return { loading: true, order: {} };
    case ORDER_DETAIL_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// get current orders
export const currentOrderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case LIST_CURRENT_ORDER_REQUEST:
      return { loading: true, orders: [] };
    case LIST_CURRENT_ORDER_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case LIST_CURRENT_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
