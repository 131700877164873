import axios from "../../axios";
import {
  CAR_TYPE_LIST_FAIL,
  CAR_TYPE_LIST_REQUEST,
  CAR_TYPE_LIST_SUCCESS,
  //
  CAR_CREATE_FAIL,
  CAR_CREATE_REQUEST,
  CAR_CREATE_SUCCESS,
  //
  CAR_LIST_FAIL,
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  //
  CAR_DELETE_FAIL,
  CAR_DELETE_REQUEST,
  CAR_DELETE_SUCCESS,
} from "../constants/carConstants";

// delete car
export const deleteCar = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/cars/delete/${id}/`, config);
    dispatch({
      type: CAR_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list cars
export const getAllCarList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/cars/?page=${page}`, config);

    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get all type car
export const getAllCarTypeList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_TYPE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/cars/types/`, config);

    dispatch({
      type: CAR_TYPE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAR_TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// create an car
export const createCar = (car) => async (dispatch, getState) => {
  try {
    console.log(car);
    dispatch({
      type: CAR_CREATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/cars/add-car/`, car, config);

    dispatch({
      type: CAR_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAR_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
