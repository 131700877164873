import React, { useEffect } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function DashboardScreen() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      // dispatch(dashStaticInfo());
    }
  }, [navigate, userInfo, dispatch]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-6 px-4 md:px-6 xl:px-7.5 flex justify-start items-center">
            <h4 className="text-xl font-semibold text-black dark:text-white">
              Statistique
            </h4>
            <div className="mx-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 21L15 17"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 21L9 17"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.925 17H5.075C3.929 17 3 16.071 3 14.925V5.075C3 3.929 3.929 3 5.075 3H18.924C20.071 3 21 3.929 21 5.075V14.924C21 16.071 20.071 17 18.925 17Z"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 13V10.27"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13V7"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.5 13V9.67999"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.5 13V7.54999"
                  stroke="#0044AD"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default DashboardScreen;
