import React from "react";

function LayoutItem(props) {
  return (
    <div className=" border border-gray rounded-md rounded-t-xl shadow-2 my-2">
      <div className="p-2 rounded-t-xl bg-gray ">{props.title}</div>
      <div className="p-4 bg-white rounded-b-xl">{props.children}</div>
    </div>
  );
}

export default LayoutItem;
