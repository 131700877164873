export const CAR_TYPE_LIST_REQUEST = "CAR_TYPE_LIST_REQUEST";
export const CAR_TYPE_LIST_SUCCESS = "CAR_TYPE_LIST_SUCCESS";
export const CAR_TYPE_LIST_FAIL = "CAR_TYPE_LIST_FAIL";

export const CAR_CREATE_REQUEST = "CAR_CREATE_REQUEST";
export const CAR_CREATE_SUCCESS = "CAR_CREATE_SUCCESS";
export const CAR_CREATE_FAIL = "CAR_CREATE_FAIL";

export const CAR_LIST_REQUEST = "CAR_LIST_REQUEST";
export const CAR_LIST_SUCCESS = "CAR_LIST_SUCCESS";
export const CAR_LIST_FAIL = "CAR_LIST_FAIL";

export const CAR_DELETE_REQUEST = "CAR_DELETE_REQUEST";
export const CAR_DELETE_SUCCESS = "CAR_DELETE_SUCCESS";
export const CAR_DELETE_FAIL = "CAR_DELETE_FAIL";
