import axios from "../../axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  //
  USER_CLIENT_REQUEST,
  USER_CLIENT_SUCCESS,
  USER_CLIENT_FAIL,
  //
  USER_DRIVER_REQUEST,
  USER_DRIVER_SUCCESS,
  USER_DRIVER_FAIL,
  //
  USER_ACTIF_DEACTIVE_REQUEST,
  USER_ACTIF_DEACTIVE_SUCCESS,
  USER_ACTIF_DEACTIVE_FAIL,
  //
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  //
  USER_CLIENT_ADD_SUCCESS,
  USER_CLIENT_ADD_REQUEST,
  USER_CLIENT_ADD_FAIL,
  //
  USER_DRIVER_ADD_SUCCESS,
  USER_DRIVER_ADD_REQUEST,
  USER_DRIVER_ADD_FAIL,
  //
} from "../constants/userConstants";

import { toast } from "react-toastify";
// add driver
export const addUserDriver = (driver) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DRIVER_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data, status } = await axios.post(
      `/users/drivers/add/`,
      driver,
      config
    );
    if (status === 200) {
      toast.success(
        data.detail ? data.detail : "Cet utilisateur a été ajouté avec succès",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    }
    dispatch({
      type: USER_DRIVER_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error(
      error.response && error.response.data.detail
        ? error.response.data.detail
        : "Cet utilisateur n'a pas été ajouté, réessayez",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );

    dispatch({
      type: USER_DRIVER_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "Cet utilisateur n'a pas été ajouté, réessayez",
    });
  }
};

// add client
export const addUserCLient = (client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CLIENT_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data, status } = await axios.post(
      `/users/clients/add/`,
      client,
      config
    );
    if (status === 200) {
      toast.success(
        data.detail ? data.detail : "Cet utilisateur a été ajouté avec succès",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    }
    dispatch({
      type: USER_CLIENT_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error(
      error.response && error.response.data.detail
        ? error.response.data.detail
        : "Cet utilisateur n'a pas été ajouté, réessayez",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );

    dispatch({
      type: USER_CLIENT_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "Cet utilisateur n'a pas été ajouté, réessayez",
    });
  }
};

// delete user
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/users/delete/${id}/`, config);
    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// deactive or active user
export const actifDeactifUser = (id, evnt) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ACTIF_DEACTIVE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(
      evnt === "actif" ? `/users/actif/${id}/` : `/users/deactif/${id}/`,
      {},
      config
    );

    dispatch({
      type: USER_ACTIF_DEACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ACTIF_DEACTIVE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// list client
export const clientsList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CLIENT_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/users/clients/?page=${page}`, config);

    dispatch({
      type: USER_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_CLIENT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// list drivers
export const driversList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DRIVER_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/users/drivers/?page=${page}`, config);

    dispatch({
      type: USER_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DRIVER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// login
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/users/login-ad/",
      { username: username, password: password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfoChauffeurMarocPrive", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// logout
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfoReparup");
  dispatch({ type: USER_LOGOUT });
  document.location.href = "/";
};
